@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--grape: 114, 35, 204;
}
html,
body {
	background-color: #000;
	box-sizing: border-box;

	padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
		env(safe-area-inset-left);
	padding-top: env(safe-area-inset-top);

	padding: 0;
	margin: 0;
	// font-family: "Montserrat", sans-serif;
	font-family: 'Ubuntu', 'Rethink Sans', sans-serif;
	&:before {
		content: '';
		content: '';
		width: 100%;
		height: 100vh;

		background-color: #000;
		position: fixed;
		left: 0;
		top: 0;
		z-index: -1;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
	}
}
.rethink-sans {
	font-family: 'Rethink Sans', sans-serif;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
}

// .ubuntu-light {
// 	font-family: 'Ubuntu', sans-serif;
// 	font-weight: 300;
// 	font-style: normal;
// }

.ubuntu-regular {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 400;
	font-style: normal;
}

.ubuntu-medium {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
	font-style: normal;
}

.ubuntu-bold {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
	font-style: normal;
}

// .ubuntu-light-italic {
// 	font-family: 'Ubuntu', sans-serif;
// 	font-weight: 300;
// 	font-style: italic;
// }

// .ubuntu-regular-italic {
// 	font-family: 'Ubuntu', sans-serif;
// 	font-weight: 400;
// 	font-style: italic;
// }

// .ubuntu-medium-italic {
// 	font-family: 'Ubuntu', sans-serif;
// 	font-weight: 500;
// 	font-style: italic;
// }

.ubuntu-bold-italic {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
	font-style: italic;
}
.text-shadow {
	text-shadow: 0px 2px 0px rgb(0 0 0 / 30%);
}

// .landing-section-bg {
// 	height: 100vh;
// 	background: linear-gradient(-45deg, rgba(229, 93, 135, 0.7), rgba(95, 195, 228, 0.7)),
// 		url(https://picsum.photos/g/2000/1200?image=443) center center / cover no-repeat;
// }

.adjacent-post {
	& .arrow-btn {
		transition: width 300ms ease;
		width: 50px;
	}

	&:hover {
		& .arrow-btn {
			width: 60px;
		}
	}
}

// .infinite-scroll-component__outerdiv{
// padding: 1px;
// display: grid !important;
// }

.link-underline {
	border-bottom-width: 3;
	background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
	background-size: 0 3px;
	background-position: 0 100%;
	background-repeat: no-repeat;
	transition: background-size 0.5s ease-in-out;
	padding-bottom: 3px;
}

.link-underline-black {
	background-image: linear-gradient(transparent, transparent), linear-gradient(#7928ca, #0f766e);
}

.link-underline:hover {
	background-size: 100% 3px;
	background-position: 0 100%;
}

// .paginationItemStyle {
// 	margin-left: 5px;

// 	.page-link {
// 		color: #fff;
// 	}

// 	&.page-item {
// 		&.active {
// 			button {
// 				background-color: rgb(186, 224, 48);
// 				border-color: green;
// 			}
// 		}
// 	}
// }

// .paginationLinkStyle {
// 	background-color: #b90000;
// 	border-radius: 5px;

// 	&:hover {
// 		background-color: #772e2e;
// 	}

// 	&:active {
// 		background-color: #772e2e;
// 	}
// }

// .react-multi-carousel-list {
// 	& .arrow-btn {
// 		transition: width 300ms ease;
// 		width: 50px;

// 		&:hover {
// 			width: 60px;
// 		}
// 	}
// }

a {
	color: inherit;
	text-decoration: none;
}

// @keyframes animate023845 {
// 	to {
// 		transform: rotate(1turn);
// 	}
// }

.layout {
	max-width: 90rem;
	margin-top: 10px;
	margin-bottom: 10px;
	@apply mx-auto w-11/12;
}

.header-layout {
	max-width: 80rem;
	@apply mx-auto w-11/12;
}

@layer components {
	.section-layout {
		//   @apply layout mx-auto lg:my-14 my-5 sm:px-2 xs:px-3 lg:px-5  text-slate-400;
	}
	.select2-search {
		@apply border border-gray-300 rounded;
	}
}

#tsparticles {
	// position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: -1;
}

::-webkit-scrollbar {
	width: 2.5px;

	/* display: none; */
}

::-webkit-scrollbar-track {
	background: #000;
	/* box-shadow: inset -10px 10px 15px #555;  */
	border-radius: 70px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	/* background: #3d3d3d; */
	background: #7928ca;
	border-radius: 70px;
}

/*Handle on hover */
::-webkit-scrollbar-track:hover {
	background: #fefefe;
}

.imageContainer {
	width: 100%;
	// height: 200px;
	/* You can add padding, margin, or other styles as needed */
}

.responsiveImage {
	max-width: 100%;
	height: auto;
}

// ANIMATION

/* Base fade-in and translateY animation */
.fade-in {
	opacity: 0;
	transform: translateY(20px);
	transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
	will-change: opacity, transform; /* Hint browser to optimize */
}

.fade-in.visible {
	opacity: 1;
	transform: translateY(0);
}

/* Add scaling for hover effect */
.card-hover {
	transition: transform 0.2s ease-in-out;
}

.card-hover:hover {
	// transform: scale(1.01);
}

/* Add pulse animation for 'Coming Soon' */
@keyframes pulse {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
}

.coming-soon {
	animation: pulse 1.5s infinite;
}

.bg-gold {
	border:1px solid #ffd700;
}

.border-gold {
	background-color: #ffd700;
}

.bg-silver {
	background-color: #c0c0c0;
}

.bg-bronze {
	background-color: #cd7f32;
}
